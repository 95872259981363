import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { navItems } from './NavItems'

const Container = styled.nav`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 4rem;
    background-color: ${props => props.theme.body}80;
    backdrop-filter: blur(6px);
    border-top: 1px solid ${props => props.theme.subText}25;
`
const Wrapper = styled.div`
    width: 100%;
    height: auto;
    background-color: ${props => props.theme.body};
    border-bottom: 1px solid ${props => props.theme.subText}25;

    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`
const Item = styled.h5`
    color: ${props => props.active ? props.theme.primary : props.theme.text};
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
`

const ResNavbar = () => {
    const location = useLocation();
    const getActive = (path) => {
        if (path === location.pathname) {
            return true
        } else {
            return false
        }
    }
    
    return (
        <Container>
            <Wrapper className='animate-slide-down'>
                {
                    navItems.map((item, index) => (
                        <Item key={index} active={getActive(item.path)}>{item.name}</Item>
                    ))
                }
            </Wrapper>
        </Container>
    )
}

export default ResNavbar