import axiosInstance from '../utils/axios.config';
import { authStart, authSuccess, authFailure, logout } from "./features/authSlice";

import toast from 'react-hot-toast';

export const handleRegister = async (dispatch, data) => {
    dispatch(authStart());

    try {
        if(data.fullname === "" || data.email === "" || data.password === "" || data.gender === "" || data.dob === "" ){
            dispatch(authFailure());
            toast.error("Please fill all the fields")
        }

        const response = await axiosInstance.post('/api/v1/register/', data);
        dispatch(authSuccess(response.data));
        toast.success("Registration successful");
    }
    catch(err) {
        dispatch(authFailure());
        toast.error(err.response.data.error);
    }
}

export const getUserInfo = (dispatch) => {
    dispatch(authStart());

    try {
        const res = axiosInstance.get('/get_info', {withCredentials: true});
        dispatch(authSuccess(res.data));
    }
    catch (err) {
        dispatch(authFailure());
    }
}

// export const handleLogin = (dispatch, data) => {
//     dispatch(authStart());
//     try{
//         const res = axiosInstance.post('/login', data);
//         dispatch(loginSuccess(res.data));
//     }
//     catch(err){
//         dispatch(authFailure(err));
//         toast.error(err.response.data.error);
//     }
//     return (
//         axiosInstance.post('/login', data).then(res => {
//             dispatch(loginSuccess(res.data));
//         }).catch(err => {
//             dispatch(authFailure());
//             toast.error(err.response.data.error);
//         })
//     )
// }

export const handleLogout = (dispatch) => {
    dispatch(logout());
}

