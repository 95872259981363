import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Hamburger from 'hamburger-react';
import { rgba } from 'polished';

import { NavLogo } from '..';
import { ThemeContext } from '../../context/ThemeContext';
// import Button from '../Button'

// import { AiOutlineMinus } from 'react-icons/ai'
import { navItems } from './NavItems';
import ResNavbar from './ResNavbar';

const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  background-color: ${props => props.changeStyle && props.theme.body};
  border-bottom: 1px solid ${props => (props.changeStyle ? rgba(props.theme.secondary, 0.25) : 'transparent')};
  box-shadow: 0px 25px 30px -20px ${props => (props.changeStyle ? rgba(props.theme.secondary, 0.15) : 'transparent')};
`;
const NavWrapper = styled.nav`
  height: max-content;
`;
const Item = styled.div`
  color: ${props => (props.active ? props.theme.primary : props.theme.text)};
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
`;

const Navbar = () => {
  const { theme } = useContext(ThemeContext);
  const [isHamBurgerOpen, setHamburgerOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const getActive = path => {
    if (path === location.pathname) {
      return true;
    } else {
      return false;
    }
  };

  const [navbarChange, setNavbarChange] = useState(false);

  const changeNavbar = () => {
    if (window.scrollY >= 60) {
      setNavbarChange(true);
    } else {
      setNavbarChange(false);
    }
  };
  useEffect(() => {
    changeNavbar();
    window.addEventListener('scroll', changeNavbar);
  });

  return (
    <Container className='sticky top-0 px-4' changeStyle={navbarChange}>
      <NavWrapper className=' py-4  flex justify-between items-center'>
        <img src={NavLogo} alt='Logo' className='w-auto h-8 cursor-pointer' onClick={() => navigate('/')} />

        <div className='hidden lg:flex gap-x-8 items-center'>
          {navItems.map((item, index) => (
            <Item className='flex gap-x-4 items-center' active={getActive(item.path)} onClick={() => navigate(item.path)} key={index}>
              <h5>{item.name}</h5>
              {/* <AiOutlineMinus className='text-[21px]' /> */}
            </Item>
          ))}
          {/* <Button text='LOGIN' strong={true} clickEvent={() => navigate('/login')}/> */}
        </div>

        <div className='flex lg:hidden'>
          <Hamburger toggled={isHamBurgerOpen} toggle={setHamburgerOpen} color={`${theme.text}`} />
        </div>
      </NavWrapper>
      {isHamBurgerOpen && <ResNavbar />}
    </Container>
  );
};

export default Navbar;
