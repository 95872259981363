import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    user: {},
    isFetching: false,
    error: null,
    isLoggedIn: false,
}

const authSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        authStart: (state) => {
            state.isFetching = true;
            state.error = null;
            state.isLoggedIn = false;
        },
        authSuccess: (state, action) => {
            state.isFetching = false;
            state.user = action.payload;
            state.error = false;
        },
        authFailure: (state) => {
            state.isFetching = false;
            state.error = true;
            state.isLoggedIn = false;
        },
        loginSuccess: (state, action) => {
            state.isFetching = false;
            state.user = action.payload;
            state.error = false;
            state.isLoggedIn = true;
        },
        logout: (state) => {
            state.user = [];
            state.isLoggedIn = false;
        }
    }
})

export const { authStart, authSuccess, authFailure, loginSuccess, logout } = authSlice.actions;
export default authSlice.reducer;