import Button from '../Button';
import Input from '../Input';
import { Container, Title, Inputs, ButtonDiv } from './DonateStyles';

const PreInfo = ({ setView, name, setName, email, setEmail, phone, setPhone, type, setType }) => {
  const inputfields = [
    {
      label: 'Full Name',
      type: 'text',
      name: 'fullName',
      value: name,
      setValue: setName,
    },
    {
      label: 'Email Address',
      type: 'email',
      name: 'email',
      value: email,
      setValue: setEmail,
    },
    {
      label: 'Contact Number',
      type: 'number',
      name: 'contact',
      value: phone,
      setValue: setPhone,
    },
    {
      label: 'Type of Donation',
      type: 'select',
      name: 'type',
      value: type,
      setValue: setType,
      options: [
        { value: 'book', label: 'Donate Books' },
        { value: 'money', label: 'Donate Funds' },
      ],
    },
  ];

  const handleNextClick = () => {
    type === 'money' ? setView('money') : setView('book');
  };

  return (
    <Container>
      <Title>
        <h2>Make a Donation</h2>
      </Title>
      <Inputs>
        {inputfields.map((item, i) => (
          <Input key={i} {...item} strong={false} />
        ))}
      </Inputs>
      <ButtonDiv>
        <Button text={'Next'} strong={true} clickEvent={handleNextClick} />
      </ButtonDiv>
    </Container>
  );
};

export default PreInfo;
