import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { MdOutlineNavigateNext, MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

import { Container, Wrapper, BlueLight, IconWrapper, InputWrapper } from './AuthStyles';

import { handleRegister } from '../../redux/apiCalls';
import { Toaster } from 'react-hot-toast';

const Register = () => {
  const { isFetching, error } = useSelector(state => state.user);
  const [fullname, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setConfirmPassword] = useState('');

  const [show, setShow] = useState({
    password: false,
    repassword: false,
  })

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    handleRegister(dispatch, {fullname, email, password, repassword, contact, dob, gender}).then(() => {
      if(error === false) {
        console.log('Working')
        setFullName("");
        setEmail("");
        setPassword("");
        setContact("");
        setDob("");
        setGender("");
      }
    });
  };

  const InputFields = [
    {
      label: 'Full Name',
      type: 'text',
      name: 'fullname',
      value: fullname,
      setValue: setFullName,
    },
    {
      label: 'Email Address',
      type: 'email',
      name: 'email',
      value: email,
      setValue: setEmail,
    },
    {
      label: 'Contact',
      type: 'number',
      name: 'contact',
      value: contact,
      setValue: setContact,
    },
    {
      label: 'Date of Birth',
      type: 'date',
      name: 'dob',
      value: dob,
      setValue: setDob,
    },
    {
      label: 'Gender',
      type: 'select',
      name: 'gender',
      options: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'other', label: 'Other' },
      ],
      value: gender,
      setValue: setGender,
    },
  ];

  const quote = {
    text: 'A Reader lives a thousand lives before he dies ...',
    continued_text: 'The Man who never reads lives only one.',
    quoteMaster: 'George R.R Martin',
  };


  return (
    <Container>
      <div className='hidden md:flex w-1/3 flex-col gap-y-4'>
        {' '}
        {/* Quote */}
        <h2>{quote?.text}</h2>
        <h2>{quote?.continued_text}</h2>
        <h4>- {quote?.quoteMaster}</h4>
      </div>

      <div className='w-full md:w-1/2 relative px-8 py-4 z-10'>
        <BlueLight className='w-44 md:w-60' />

        <Wrapper className='p-8 md:p-12'>
          <h2>Create an account</h2>

          <div>
            {InputFields.map((item, i) => (
              <Input key={i} {...item} strong={true} />
            ))}
            <InputWrapper>
              <label>Password</label>
              <input type={show.password ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
              {
                show.password ?
                <MdOutlineVisibility className='text-[21px] absolute right-4 bottom-2' onClick={() => setShow({...show, password: false})}/>
                :
                <MdOutlineVisibilityOff className='text-[21px] absolute right-4 bottom-2' onClick={() => setShow({...show, password: true})}/>
              }
            </InputWrapper>
            <InputWrapper>
              <label>Confirm Password</label>
              <input type={show.repassword ? 'text' : 'password'} value={repassword} onChange={(e) => setConfirmPassword(e.target.value)} />
              {
                show.repassword ?
                <MdOutlineVisibility className='text-[21px] absolute right-4 bottom-2' onClick={() => setShow({...show, repassword: false})}/>
                :
                <MdOutlineVisibilityOff className='text-[21px] absolute right-4 bottom-2' onClick={() => setShow({...show, repassword: true})}/>
              }
            </InputWrapper>
          </div>

          <div>
            <Button text='Register' clickEvent={handleRegisterSubmit} strong={true} disabled={isFetching}/>
          </div>
        </Wrapper>

        <IconWrapper className='absolute left-0 top-1/2 rotate-180' onClick={() => navigate('/login')}>
          <MdOutlineNavigateNext className='text-[27px]' />
        </IconWrapper>
      </div>

      <Toaster />
    </Container>
  );
};
export default Register;
