import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    books: [
        {
            _id: '',
            title: '',
            author: {
                name: '',
                slug: '',
            },
            genre: [
                {
                    name: '',
                    slug: '',
                }
            ],
            cover: '',
            slug: '',
        }
    ],
    selected_book: {
        title: '',
        author: {
            name: '',
            slug: '',
        },
        genre: [
            {
                name: '',
                slug: '',
            },
        ],
        rating: '',
        cover: '',
        isbn: '',
        slug: '',
    },
    isFetching: false,
    error: null,
}

const booksSlice = createSlice({
    name: "books",
    initialState,
    reducers: {
        getBooksStart: (state) => {
            state.isFetching = true;
            state.error = null;
        },
        getBooksSuccess: (state, action) => {
            state.isFetching = false;
            state.books = action.payload;
            state.error = false;
        },
        getBookBySlugSuccess: (state, action) => {
            state.isFetching = false;
            state.selected_book = action.payload;
            state.error = false;
        },
        getBooksFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    }
})

export const { getBooksStart, getBooksSuccess, getBooksFailure, getBookBySlugSuccess } = booksSlice.actions;
export default booksSlice.reducer;