import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Button from '../Button'

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    li {
        color: ${props => props.theme.subText};
        font-size: 18px;
        font-weight: 300;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;

        &:hover {
            color: ${props => props.theme.text};
        }
    }
`
const Input = styled.input`
    border: none;
    background-color: ${props => props.theme.default};
    color: ${props => props.theme.primary};
    font-size: 18px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    width: 100%;

    &:focus {
        outline: none;
    }
`

const QuickLinks = () => {
    const [email, setEmail] = useState('');
  
    const navigate = useNavigate();
    
    return (
        <div className='flex flex-col lg:flex-row gap-y-16 justify-between items-center'>
            <div className='w-full lg:w-auto flex justify-between lg:justify-start gap-x-24'>
                <div className='flex flex-col gap-y-8'>
                    <h3 className='uppercase'>Quick Links</h3>
                    <List>
                        <li onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        }}>
                            Home
                        </li>
                        <li onClick={() => navigate('/books')}>Books</li>
                        <li onClick={() => navigate('/wisdom-point')}>Wisdom Points</li>
                        <li onClick={() => navigate('/user/:id')}>Profile</li>
                    </List>
                </div>
                <div className='flex flex-col gap-y-8'>
                    <h3 className='uppercase'>Legal</h3>
                    <List>
                        <li>Terms & Conditions</li>
                        <li>Privacy Policy</li>
                    </List>
                </div>
            </div>

            <div className='w-full lg:w-auto flex flex-col gap-y-12 items-start lg:items-end'>
                <div>
                    <h3 className='uppercase'>SUBSCRIBE AND STAY CONNECTED WITH US</h3>
                    <p>Get notified instantly</p>
                </div>

                <div className='w-full flex gap-x-4'>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <Button strong={true} text='Subscribe' />
                </div>
            </div>
        </div>
    )
}

export default QuickLinks