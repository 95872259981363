import React, { useEffect } from 'react';
import styled from 'styled-components';

import { VisionImg } from '..';

import Aos from 'aos';
import 'aos/dist/aos.css';

const Container = styled.section`
  width: 100%;
  display: flex;
`;
const TraingleRight = styled.div`
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 16px solid ${props => props.theme.primary};
  border-bottom: 8px solid transparent;
`;

const Vision = () => {
  const contents = {
    title: 'Learn what we seek in our long term service.',
    visions: ['To provide open and easy access to cultivate reading culture among  bibliophiles.', 'To promote inclusivity and uplift the education status in the long run.'],
  };

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <Container className='flex-col-reverse lg:flex-row items-center lg:justify-between gap-y-4 '>
      <div className='w-3/4 lg:flex-1 flex flex-col gap-y-4' data-aos='zoom-in-right'>
        <h2>{contents.title}</h2>
        <div className='flex flex-col gap-y-2'>
          {contents.visions.map((vision, index) => (
            <div className='flex gap-x-4 items-center' key={index}>
              <TraingleRight />
              <p>{vision}</p>
            </div>
          ))}
          <p className='mt-2'>
            For those who need their space, don't have time to run to library, are withholding themselves because of physical, social, linguistic, or economic barriers. We are here for you.{' '}
          </p>
        </div>
      </div>

      <div className='flex-1' data-aos='zoom-in-left'>
        <img src={VisionImg} alt='Vision' className='w-full h-96 object-contain' />
      </div>
    </Container>
  );
};

export default Vision;
