import React, { useEffect } from 'react'
import styled from 'styled-components'

import { Point } from '..'

import Aos from 'aos'
import 'aos/dist/aos.css';

const Container = styled.section`
    width: 100%;
    display: flex;
`

const Wisdom = () => {
    const contents = {
        title: 'Been going through every library in search of a single book?',
        description: "We are here to solve this particular problem and help the readers to save their time by showing exactly where the book they are interested in can be found. We have our facility spread across many areas of the cities and have absolute records for all the available books. Search for your favourite book and trace where the book is available and can be lent from.",
    }

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, [])

    return (
        <Container className="flex-col-reverse lg:flex-row items-center lg:justify-between gap-y-2 gap-x-4  ">
            <div className='w-3/4 lg:flex-1 flex flex-col gap-y-4' data-aos="zoom-in-right">
                <h2>{contents.title}</h2>
                <p>{contents.description}</p>
            </div>

            <div className='flex-1' data-aos="zoom-in-left">
                <img src={Point} alt="wisdom" className="w-full h-auto object-cover" />
            </div>
        </Container>
    )
}

export default Wisdom