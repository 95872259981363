import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.section`
  padding: 0 1rem;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`
export const Wrapper = styled.div`
  max-height: 98vh;
  background-color: ${props => props.theme.body}25;
  backdrop-filter: blur(1rem) brightness(1.1);
  border-radius: 14px;
  border: 1px solid ${props => rgba(props.theme.body, 1)};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: auto;

  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0;
  }
`
export const BlueLight = styled.div`
  position: absolute;
  height: 0.75rem;
  border-radius: 4px;
  bottom: 0.5rem;
  right: 50%;
  transform: translateX(50%);
  z-index: -1;
  background-color: ${props => props.theme.primary};
`
export const Link = styled.div`
  font-family: 'Roboto', sans-serif;
  color: ${props => props.theme.secondary};
  cursor: pointer;
`
export const IconWrapper = styled.div`
  background-color: ${props => props.theme.text}25;
  border: 1px solid ${props => props.theme.text};
  border-radius: 50%;
  padding: 0.5rem;
  color: ${props => props.theme.text};
  backdrop-filter: blur(2px);

  &:hover {
    background-color: ${props => props.theme.text}50;
  }
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: relative;
 
  label {
    margin-bottom: 5px;
  }
  
  input {
    height: 40px;
    border-radius: 5px;
    background-color: ${props => props.theme.default};

    color: ${props => props.theme.primary};

    padding: 0 10px;
    outline: none;
  }
`