export const themes = {
    light: {
        dark: false,
        body: '#EFFFFD',
        text: '#26282B',
        subText: '#8B8B8B',
        primary: '#47B5FF',
        secondary: '#1363DF',
        neutral: '#ffffff',
        default: '#DFF6FF', //form input field bg color
        fadedText: '#26282B20', // for background text
    },
    dark: {
        dark: true,
        body: '#26282B',
        text: '#DFF6FF',
        subText: '#8B8B8B',
        primary: '#1363DF',
        secondary: '#47B5FF',
        neutral: '#ffffff',
        default: '#DFF6FF', //form input field bg color
        fadedText: '#C8C8C820', // for background text
    }
}