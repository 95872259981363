import axios from "axios";
// const access_token = localStorage.getItem('access_token');

const axiosInstance = axios.create({
    baseURL: "https://api-thewisdompoint-com.onrender.com/",
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer '+ access_token
    }
});

axiosInstance.defaults.xsrfCookieName = 'csrftoken'
axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken'

export default axiosInstance;