import React from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'

import BooksCard from './BooksCard'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`
export const Block = styled.div`
    background-color: ${props => props.theme.primary}15;
`

const ListBooks = ({ books, isFetching }) => {
    const navigate = useNavigate();

    return (
        <Container className='gap-x-12 gap-y-16'>
            {
                isFetching ? 
                <>
                    {
                        [0, 1, 2].map(index => (
                            <div className='flex justify-start gap-x-2 animate-pulse' key={index}>
                                <Block className='w-52 h-72 rounded-[4px]' />
                                <div className='w-44 h-72 flex flex-col justify-between'>
                                    <div className='flex flex-col gap-y-4'>
                                        <Block className='w-full h-8 rounded-[4px]' />
                                        <Block className='w-full h-4 rounded-[4px]' />
                                    </div>
                                    <Block className='w-full h-16 rounded-[4px]' />
                                </div>
                            </div>
                        ))
                    }
                </>
                :
                <>
                    {
                        books?.map((item, index) => (
                            <BooksCard 
                                key={index}
                                img={item?.cover} 
                                title={item?.title} 
                                author={item?.author.name} 
                                genre={item?.genre[0].name}
                                clickEvent={() => navigate(`/books/${item?.slug}`)}
                            />
                        ))
                    }
                </>
            }
        </Container>
    )
}

export default ListBooks