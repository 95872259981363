import styled from 'styled-components';
import { GoSearch } from 'react-icons/go';
import { devices } from '../../assets/responsive';

const Container = styled.div`
  height: 17vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10vh;
`;
const Hr = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.subText};
  margin-bottom: 10px;
`;
const Filters = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  @media ${devices.mobileL} {
    flex-direction: column;
    align-items: center;
  }
`;

const DateFilter = styled.select`
  background-color: transparent;
  color: ${props => props.theme.text};
  width: 70px;
  outline: none;
  border: 1px solid ${props => props.theme.text};
  option {
    background-color: ${props => props.theme.body};
    width: 100%;
  }
  @media ${devices.mobileL} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
`;

const SearchContainer = styled.div`
  width: 20vw;
  position: relative;
  input {
    width: 100%;
    padding: 0.2rem 1rem;
    background-color: transparent;
    outline: none;
    border: 1px solid ${props => props.theme.subText};
    color: ${props => props.theme.subText};
    border-radius: 5px;
  }
  span {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: ${props => props.theme.subText};
  }
  @media ${devices.mobileL} {
    width: 100%;
  }
`;

const Top = () => {
  return (
    <Container>
      <h1>Financial Reports</h1>
      <Hr></Hr>
      <Filters>
        <DateFilter>
          <option value='2022'>2022</option>
          <option value='2021'>2021</option>
          <option value='2020'>2020</option>
          <option value='2019'>2019</option>
          <option value='2018'>2018</option>
        </DateFilter>
        <SearchContainer>
          <input type='text' placeholder='Search' />
          <span>
            <GoSearch />
          </span>
        </SearchContainer>
      </Filters>
    </Container>
  );
};
export default Top;
