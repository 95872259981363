import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

const Btn = styled.button`
  width: max-content;
  background-color: ${props => props.strong ? props.theme.primary : rgba(props.theme.primary, 0.25)};
  border: 1px solid ${props => props.strong ? 'transparent' : props.theme.primary};
  border-radius: 4px;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  color: ${props => props.strong ? props.theme.text : props.theme.primary};
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  
  &:hover {
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.text};
  }

  &:disabled {
    background-color: ${props => props.theme.body}50;
    backdrop-filter: blur(2px) brightness(1.25);
    cursor: not-allowed;
  }

  span {
    color: ${props => props.theme.primary};
  }
`
const Loader = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 50%;

  border-top: 3px solid ${props => props.theme.primary};
  border-left: 3px solid ${props => props.theme.primary};
  border-right: 3px solid ${props => props.theme.primary}00;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 0.5s linear infinite;
`

const Button = ({ text, clickEvent, strong, disabled }) => {
  return (
    <Btn strong={strong} onClick={clickEvent} disabled={disabled}>
      {
        disabled ?
        <div className='flex gap-x-4 items-center'>
          <Loader />
          <span>Processing</span>
        </div>
        :
        <>
          {text}
        </>
      }
    </Btn>
  )
};

export default Button;

