import styled from 'styled-components';
import { devices } from '../../assets/responsive';

const Container = styled.div`
  height: 17vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  @media ${devices.mobileL} {
    height: 13vh;
  }
`;

const Top = styled.div`
  height: 75%;
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    object-fit: contain;
  }
`;

const Bottom = styled.h6`
  color: ${props => props.theme.text};
  font-size: 1.2rem;
  @media ${devices.mobileL} {
    font-size: 0.8rem;
  }
`;

const PdfCard = ({ img, link, title }) => {
  return (
    <Container>
      <Top>
        <img src={img} alt='' />
      </Top>
      <Bottom>{title}</Bottom>
    </Container>
  );
};
export default PdfCard;
