import styled from 'styled-components';
import pdf from '../assets/images/pdf.png';
import { devices } from '../assets/responsive';
import PdfCard from '../components/ReportsComponents/PdfCard';
import Top from '../components/ReportsComponents/Top';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 150px);
  padding: 4rem 0;
`;

const Bottom = styled.div`
  padding: 0 10px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: start;
  row-gap: 1rem;
  column-gap: 2rem;
  @media ${devices.tablet} {
    justify-content: center;
    column-gap: 4rem;
  }
`;

const data = [
  {
    img: pdf,
    title: 'Financial Report 1',
    link: '',
  },
  {
    img: pdf,
    title: 'Financial Report 2',
    link: '',
  },
  {
    img: pdf,
    title: 'Financial Report 3',
    link: '',
  },
  {
    img: pdf,
    title: 'Financial Report 4',
    link: '',
  },
];

const Reports = () => {
  return (
    <Container>
      <Top />
      <Bottom>
        {data.map((item, i) => (
          <PdfCard {...item} index={i} />
        ))}
      </Bottom>
    </Container>
  );
};
export default Reports;
