import React from 'react';
import styled from 'styled-components';
import { LandingImage } from '..';

// import HTMLFlipBook from 'react-pageflip';
// import { Image1, Image2, Image3, Image4 } from '..';
import Button from '../Button';

const Container = styled.section`
  width: 100%;
  gap: 1rem;
  align-items: center;
`;
// const Title = styled.h1`
//     font-family: 'Montserrat', sans-serif;
//     font-size: 44px;
//     font-weight: 700;
// `

const Welcome = () => {
  return (
    <Container className='md:min-h-[550px] lg:min-h-[700px] flex justify-between items-center leading-tight relative'>
      <div className='w-1/2 flex flex-col gap-8'>
        <h1>Wisdom Point</h1>
        <p>
          “The Wisdom Point” — “ “म पढेर बन्छु।“ is a public library for the bookworms of Nepal. This initiative aims to promote the reading culture and life-long reading habits among the youth of
          Nepal.
        </p>
        <p>
          Wisdom point aims to encourage every group (youths, children, women, underprivileged, differently abled and older) of people into a reading culture by providing open and easy access to books
          and reading spaces despite varied socio-economic and geographic conditions.
        </p>
        <Button text={'Get Started'} strong={true} />
      </div>

      <div className='absolute h-auto w-3/4 top-28 right-0'>
        <img src={LandingImage} alt='Book' className='w-full h-auto' />
      </div>
    </Container>
  );
};

export default Welcome;
