import axiosInstance from "../../utils/axios.config";
import { getBookBySlugSuccess, getBooksFailure, getBooksStart, getBooksSuccess } from "../features/booksSlice";

export const getAllBooks = async (dispatch) => {
    dispatch(getBooksStart());

    try {
        const res = await axiosInstance.get('/books/get_books');
        dispatch(getBooksSuccess(res.data.books));
    }
    catch (err) {
        dispatch(getBooksFailure());
    }
}

export const getBookBySlug = async (dispatch, slug) => {
    dispatch(getBooksStart());

    try {
        const res = await axiosInstance.get(`/books/get_book/${slug}`);
        dispatch(getBookBySlugSuccess(res.data.book));
    }
    catch (err) {
        dispatch(getBooksFailure());
    }
}