import React from 'react'
import styled from 'styled-components'

import { TiArrowUp } from 'react-icons/ti'

const Container = styled.div`
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 1;
    padding: 0.75rem;
    border-radius: 8px;
    background-color: ${props => props.theme.body}50;
    backdrop-filter: blur(2px) brightness(1.25);
    border: 1px solid ${props => props.theme.text}15;
    cursor: pointer;
    color: ${props => props.theme.primary};
`

const TopButton = () => {
  return (
    <Container onClick={() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }}>
        <TiArrowUp className='text-[27px]'/>
    </Container>
  )
}

export default TopButton