import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getAllBooks } from '../../../redux/calls/apiBooks';

import BooksCard from '../BooksCard'
import { Block } from '../ListBooks';

const MoreBooks = () => {
    const { books, isFetching } = useSelector(state => state.books);

    const navigate = useNavigate();

    const dispatch = useDispatch()
    useEffect(() => {
        getAllBooks(dispatch);
    }, [dispatch]);

    return (
        <div className='w-full flex flex-col gap-y-8'>
            <h2>Other similar books</h2>
            {
                isFetching ? 
                <>
                    {
                        [0, 1, 2].map(index => (
                            <div className='flex justify-start gap-x-2 animate-pulse' key={index}>
                                <Block className='w-52 h-72 rounded-[4px]' />
                                <div className='w-44 h-72 flex flex-col justify-between'>
                                    <div className='flex flex-col gap-y-4'>
                                        <Block className='w-full h-8 rounded-[4px]' />
                                        <Block className='w-full h-4 rounded-[4px]' />
                                    </div>
                                    <Block className='w-full h-16 rounded-[4px]' />
                                </div>
                            </div>
                        ))
                    }
                </>
                :
                <>
                    <div className='w-full flex flex-wrap gap-x-12 gap-y-16'>
                        {
                            books?.map((item, index) => (
                                <BooksCard 
                                    key={index}
                                    img={item?.cover} 
                                    title={item?.title} 
                                    author={item?.author?.name} 
                                    genre={item?.genre[0]?.name}
                                    clickEvent={() => {navigate(`/books/${item?.slug}`)}}
                                />
                            ))
                        }
                    </div>
                </>
            }       
        </div>
    )
}

export default MoreBooks