import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import Footer from '../components/FooterComponents/Footer';
import Explore from '../components/LandingComponents/Explore';
import Mission from '../components/LandingComponents/Mission';

// import Slider from '../components/LandingComponents/Slider';
import SomeBooks from '../components/LandingComponents/SomeBooks';
import TopButton from '../components/LandingComponents/TopButton';
import Vision from '../components/LandingComponents/Vision';
import Welcome from '../components/LandingComponents/Welcome';
import Wisdom from '../components/LandingComponents/Wisdom';
import Navbar from '../components/NavbarComponents/Navbar';

export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12rem;
  position: relative;
`

const Home = () => {
  const [visible, setVisible] = useState(false);

  const handleScrollButton = () => {
    if(window.scrollY >= 100) {
      setVisible(true)
    }
    else {
      setVisible(false)
    }
  }

  useEffect(() => {
    handleScrollButton()
    window.addEventListener('scroll', handleScrollButton)
  })

  return (
    <Section>
      <Navbar />
      <Container>
          <Welcome />
          {/* <Slider /> */}
          <SomeBooks />
          <Wisdom />
          <Mission />
          <Vision />
          <Explore />
          <Footer />
          {
            visible &&
            <TopButton />
          }
      </Container>
    </Section>
  )
}

export default Home