import styled from 'styled-components';
import { devices } from '../../assets/responsive';

export const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  @media ${devices.tablet} {
    margin-bottom: 30px;
  }
`;

export const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${devices.tablet} {
    margin-bottom: 20px;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
