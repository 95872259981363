export const navItems = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'Books',
    path: '/books',
  },
  {
    name: 'Wisdom Points',
    path: '/wisdom-point',
  },
  {
    name: 'Make a Donation',
    path: '/donate',
  },
];
