// import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  label {
    margin-bottom: 5px;
  }
  
  input {
    height: 40px;
    border-radius: 5px;
    background-color: ${props => props.theme.default};

    color: ${props => props.theme.primary};

    padding: 0 10px;
    outline: none;
  }

  input,
  select {
    height: 40px;
    border-radius: 5px;
    background-color: ${props => (props.back ? props.theme.default : 'transparent')};

    color: ${props => (props.back ? props.theme.primary : props.theme.text)};
    padding: 0 10px;
    outline: none;
    border: ${props => (!props.back ? `1px solid ${props.theme.subText}` : 'none')};
    margin-bottom: 10px;
    option {
      height: 30px;
      background-color: ${props => (props.back ? props.theme.default : props.theme.body)};
      color: ${props => (props.back ? props.theme.primary : props.theme.text)};
      padding: 5px;
    }
  }
`;

const Input = ({ label, type, name, value, setValue, options, strong }) => {
  return (
    <Container back={strong}>
      <label htmlFor={name}>{label}</label>
      {type !== 'select' ? (
        <input type={type} name='' value={value} onChange={e => setValue(e.target.value)} />
      ) : (
        <>
          <select onChange={e => setValue(e.target.value)} defaultValue={options[0]}>
            <option selected disabled>{'Select an option'}</option>
            {options.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </>
      )}
    </Container>
  );
};

export default Input;
