import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '../Button';
import Input from '../Input';
import { Container, Title, Inputs, ButtonDiv } from './DonateStyles';

const Book = ({ setView, bookType, setBookType, num, setNum, pickup, setPickup, infoView, setInfoView, location, setLocation, clickEvent }) => {
  const { isFetching } = useSelector((state) => state.donate_data);

  const inputfields = [
    {
      label: 'Books Type',
      type: 'select',
      name: 'booktype',
      value: bookType,
      setValue: setBookType,
      options: [
        { label: 'Academic Books', value: 'academic' },
        { label: 'Non-Academic Books', value: 'non-academic' },
      ],
    },
    {
      label: 'Number of Books',
      type: 'select',
      name: 'number',
      value: num,
      setValue: setNum,
      options: [
        { label: '1 to 5', value: '1-5' },
        { label: '5 to 10', value: '5-10' },
        { label: 'more than 10', value: 'more' },
      ],
    },
    {
      label: 'Pickup Option',
      type: 'select',
      name: 'option',
      value: pickup,
      setValue: setPickup,
      options: [
        { label: 'Drop books to one of the wisdom points', value: 'drop' },
        { label: 'Home Pickup', value: 'pickup' },
      ],
    },
  ];

  const PickupField = {
    label: 'Pickup Location',
    type: 'text',
    name: 'location',
    value: location,
    setValue: setLocation,
  };

  const DropField = {
    label: 'Select a Wisdom Point closest to you',
    type: 'select',
    name: 'location',
    value: location,
    setValue: setLocation,
    options: [
      { label: 'Wisdom Point 1', value: '1' },
      { label: 'Wisdom Point 2', value: '2' },
      { label: 'Wisdom Point 3', value: '3' },
    ],
  };

  useEffect(() => {
    pickup ? setInfoView(true) : setInfoView(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickup]);

  const handleBackClick = () => {
    setView('preinfo');
  };

  return (
    <Container>
      <Title>
        <h2>Donate Books</h2>
      </Title>
      <Inputs>
        {inputfields.map((item, i) => (
          <Input key={i} {...item} strong={false} />
        ))}
        {infoView && pickup === 'drop' ? <Input {...DropField} strong={false} /> : pickup === 'pickup' ? <Input {...PickupField} strong={false} /> : null}
      </Inputs>
      <ButtonDiv>
        <Button text={'Back'} strong={false} clickEvent={handleBackClick} />
        <Button text={'Submit'} strong={true} clickEvent={clickEvent} disabled={isFetching}/>
      </ButtonDiv>
    </Container>
  );
};
export default Book;
