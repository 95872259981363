import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux';

import Aos from 'aos'
import 'aos/dist/aos.css';

import BooksCard from '../BooksComponents/BooksCard'
import { getAllBooks } from '../../redux/calls/apiBooks';
import { Block } from '../BooksComponents/ListBooks';

const Container = styled.article`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`

const SomeBooks = () => {
  const { books, isFetching } = useSelector(state => state.books);

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, [])

  const navigate = useNavigate();

  const dispatch = useDispatch()
  useEffect(() => {
      getAllBooks(dispatch);
  }, [dispatch]);

  return (
    <Container className='flex-wrap lg:flex-nowrap' data-aos="zoom-in-up">
      {
        isFetching ? 
        <>
            {
                [0, 1, 2].map(index => (
                    <div className='flex justify-start gap-x-2 animate-pulse' key={index}>
                        <Block className='w-52 h-72 rounded-[4px]' />
                        <div className='w-44 h-72 flex flex-col justify-between'>
                            <div className='flex flex-col gap-y-4'>
                                <Block className='w-full h-8 rounded-[4px]' />
                                <Block className='w-full h-4 rounded-[4px]' />
                            </div>
                            <Block className='w-full h-16 rounded-[4px]' />
                        </div>
                    </div>
                ))
            }
        </>
        :
        <>
          {
            books?.slice(0, 3).map((item, index) => (
              <BooksCard 
                key={index} 
                img={item?.cover} 
                title={item?.title} 
                author={item?.author?.name} 
                genre={item?.genre[0]?.name} 
                clickEvent={() => navigate(`/books/${item?.slug}`)}  
              />
            ))
          }
        </>
      }
    </Container>
  )
}

export default SomeBooks