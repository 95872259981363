import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    border-top: 1px solid ${props => props.theme.subText}25;
    padding: 1rem 0;
`

const Copyright = () => {
  return (
    <Container>
        <h4>Copyright © 2020. All Rights Reserved.</h4>
    </Container>
  )
}

export default Copyright