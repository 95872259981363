import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { getBookBySlug } from '../../../redux/calls/apiBooks'
import { Block } from '../ListBooks'

const Container = styled.div`
    width: 100%;
    gap: 3rem;

    img {
        box-shadow: 60px 45px 30px 0px rgba(0,0,0,0.10);
    }
`
const Icon = styled.div`
    color: #ffc107;
    font-size: 21px;
`
const GenreWrapper = styled.div`
    border: 1px solid ${props => props.theme.text};
    padding: 0.5rem 2rem;
    border-radius: 4px;
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.text}15;

    cursor: pointer;
`

const BookDetail = () => {
    const { selected_book, isFetching } = useSelector(state => state.books);
    
    console.log(selected_book);

    const { slug } = useParams();

    const dispatch = useDispatch()

    useEffect(() => {
        getBookBySlug(dispatch, slug)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, slug])

    if(isFetching) {
        return (
            <Container className='w-full flex flex-col md:flex-row animate-pulse'>
                <Block className='w-[250px] md:w-[350px] h-[500px] md:h-[600px] rounded' />
                <div className='flex flex-col gap-y-8'>
                    <div className='flex flex-col gap-4'>
                        <Block className='w-72 h-8 rounded' />
                        <Block className='w-20 h-4 rounded' />
                        
                        <Block className='w-32 h-8 rounded' />
                    </div>
                    <div className='flex flex-col gap-4'>
                        <Block className='w-20 h-8 rounded' />
                        <div className='flex gap-x-4'>
                            <Block className='w-36 h-14 rounded' />
                            <Block className='w-36 h-14 rounded' />
                            <Block className='w-36 h-14 rounded' />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <Block className='w-36 h-8 rounded' />
                        <Block className='w-[calc(1366px - 250px)] h-52 rounded' />
                    </div>
                </div>
            </Container>
        )
    }

    return (
        <Container className='flex flex-col md:flex-row'>
            <img src={selected_book?.cover} alt={selected_book?.title} className='h-[500px] md:h-[600px] w-auto rounded-[4px]' />
            <div className='flex flex-col gap-y-8'>
                <div>
                    <h1>{selected_book?.title}</h1>
                    <p>{selected_book?.author.name}</p>
                    <div className='flex gap-x-2 mt-8'> {/* stars */}
                        {
                            [0, 1, 2, 3].map((index) => (
                                <Icon key={index}>
                                    <AiFillStar />
                                </Icon>
                            ))
                        }
                        <Icon>
                            <AiOutlineStar />
                        </Icon>
                    </div>
                </div>

                <div className='flex flex-col gap-y-4'> {/* genre */}
                    <h3>Genre</h3>
                    <div className='flex flex-wrap gap-4'>
                        {
                            selected_book?.genre?.map((item, index) => (
                                <GenreWrapper key={index}>
                                    {item.name}
                                </GenreWrapper>
                            ))
                        }
                    </div>
                </div>
                
                <div className='flex flex-col gap-y-4'> {/* description */}
                    <h3>Summary</h3>
                    <p>{selected_book?.summary}</p>
                </div>
            </div>
        </Container>
    )
}

export default BookDetail