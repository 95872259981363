import React from 'react'
import styled from 'styled-components'
import Copyright from './Copyright'
import QuickLinks from './QuickLinks'
import Social from './Social'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
`

const Footer = () => {
  return (
    <Container className=' ' id='about'>
        <Social />
        <QuickLinks />
        <Copyright />
    </Container>
  )
}

export default Footer