import React from 'react'
import styled from 'styled-components'

import { LocationGif } from '../components'
import Navbar from '../components/NavbarComponents/Navbar'
import { Section } from './Home'

const Container = styled.div`
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

const Wisdom = () => {
  return (
    <Section>
      <Navbar />
      <Container>
          <img src={LocationGif} alt='Location' className='w-32 h-auto' />
          <h4>This feature is currently in development. Please be patience.</h4>
      </Container>
    </Section>
  )
}

export default Wisdom