import React from 'react'
import styled from 'styled-components'
import BookDetail from '../../components/BooksComponents/DetailsComponents/BookDetail'
import MoreBooks from '../../components/BooksComponents/DetailsComponents/MoreBooks'
import Navbar from '../../components/NavbarComponents/Navbar'
import { Section } from '../Home'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  margin: 3rem 0;
`

const Details = () => {
  return (
    <Section>
      <Navbar />
      <Container className=' '>
        <BookDetail />
        <MoreBooks />
      </Container>
    </Section>
  )
}

export default Details