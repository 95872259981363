import React from 'react'
import styled from 'styled-components'

import Button from '../Button'

const Image = styled.img`
  box-shadow: 20px 15px 20px 0px rgba(0,0,0,0.10);
`
const Faded = styled.h1`
  color: ${props => props.theme.fadedText};
  font-size: 44px;
  font-family: 'Satisfy', cursive;
`

const BooksCard = ({ img, title, author, genre, clickEvent }) => {
  return (
    <div className='w-full lg:w-[24rem] h-[18rem] flex flex-col md:flex-row gap-x-4 gap-y-4 items-center'>
        <Image src={img} alt={title} className='w-1/2 md:w-1/2 h-full object-cover rounded-[4px]' />
        <div className='w-1/2 flex flex-col gap-8 items-center md:items-start'>
          <div className='flex flex-col items-center md:items-start'>
            {
              title.length > 25 ?
              <h3>{title.slice(0, 25)}...</h3>
              :
              <h3>{title.slice(0, 25)}</h3>
            }
            <p>{author}</p>
          </div>

          <Faded>{genre.split(" ")[0]}</Faded>

          <Button text='Learn More' strong={false} clickEvent={clickEvent}/>
        </div>
    </div>
  )
}

export default BooksCard