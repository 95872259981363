import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    donate_data: {},
    isFetching: false,
    error: null,
}

const donateSlice = createSlice({
    name: "donate_data",
    initialState,
    reducers: {
        donateStart: (state) => {
            state.isFetching = true;
            state.error = null;
        },
        donateSuccess: (state, action) => {
            state.isFetching = false;
            state.donate_data = action.payload;
            state.error = false;
        },
        donateFailure: (state) => {
            state.isFetching = false;
            state.error = true;
            state.isLoggedIn = false;
        },
    }
})

export const { donateStart, donateSuccess, donateFailure } = donateSlice.actions;
export default donateSlice.reducer;