import React, { useEffect } from 'react';
import styled from 'styled-components';

import { MissionImg } from '..';

import Aos from 'aos';
import 'aos/dist/aos.css';

const Container = styled.section`
  width: 100%;
  display: flex;
`;
const TraingleRight = styled.div`
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 16px solid ${props => props.theme.primary};
  border-bottom: 8px solid transparent;
`;

const Mission = () => {
  const contents = {
    title: 'Learn what we seek in our long term service.',
    missions: [
      'To overcome the problem of poverty, illiteracy, and inaccessibility through mass outreach to coincide both knowledge and accessibility. ',
      'To lift the basic necessities of people such as space provision, e-facilities, and audio facilities.',
      'To maintain a peaceful and creative environment for the readers.',
      'To provide space for hosting different meetings and seminars.',
    ],
  };

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <Container className='flex-col-reverse lg:flex-row-reverse lg:justify-between items-center gap-4  '>
      <div className='w-3/4 lg:flex-1 flex flex-col gap-y-4' data-aos='zoom-in-right'>
        <h2>{contents.title}</h2>
        <div className='flex flex-col gap-y-2'>
          {contents.missions.map((mission, index) => (
            <div className='flex gap-x-4 items-center' key={index}>
              <TraingleRight />
              <p>{mission}</p>
            </div>
          ))}
        </div>
      </div>

      <div className='flex-1' data-aos='zoom-in-left'>
        <img src={MissionImg} alt='Mission' className='w-full h-96 object-contain' />
      </div>
    </Container>
  );
};

export default Mission;
