import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import Global from './assets/Global';
import { ThemeContext } from './context/ThemeContext';

import Home from './pages/Home';
import Login from './pages/Auth/Login';

import Register from './pages/Auth/Register';

import Books from './pages/Book/Books';
import Details from './pages/Book/Details';
import Wisdom from './pages/Wisdom';
import Donate from './pages/Donate';
import Reports from './pages/Reports';

const RootContainer = styled.main`
  background-color: ${props => props.theme.body};
  min-height: 100vh;
`;

const Container = styled.article`
  max-width: 1366px;
  padding: 0 1rem;
  margin: 0 auto;
  background-color: ${props => props.theme.body};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const App = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Global />
        <RootContainer>
          <Container>
            <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/login' exact element={<Login />} />
              <Route path='/register' exact element={<Register />} />
              <Route path='/books' element={<Books />} />
              <Route path='/books/:slug' element={<Details />} />
              <Route path='/wisdom-point' element={<Wisdom />} />
              <Route path='/donate' element={<Donate />} />
              <Route path='/reports' element={<Reports />} />
            </Routes>
          </Container>
        </RootContainer>
      </Router>
    </ThemeProvider>
  );
};

export default App;
