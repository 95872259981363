import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import toast, { Toaster } from 'react-hot-toast';

import girl from '../assets/images/girl.png';
import Money from '../components/DonateComponents/Money';
import Book from '../components/DonateComponents/Book';
import PreInfo from '../components/DonateComponents/PreInfo';
import { devices } from '../assets/responsive';
import Navbar from '../components/NavbarComponents/Navbar';
import axiosInstance from '../utils/axios.config';

import { donateFailure, donateStart, donateSuccess } from '../redux/features/donateSlice';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 150px);
  padding: 2rem 0;
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  @media ${devices.laptop} {
    /* align-items: center; */
    flex-direction: column;
  }
`;

const Left = styled.div`
  overflow-y: hidden;
  flex: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Donate = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [type, setType] = useState('');
  const [bookType, setBookType] = useState('');
  const [num, setNum] = useState('');
  const [pickup, setPickup] = useState('');
  const [infoView, setInfoView] = useState(false);
  const [location, setLocation] = useState('');
  const [view, setView] = useState('preinfo');
  const [amount, setAmount] = useState('');

  const handleBookSubmit = async e => {
    e.preventDefault();
    const submitData = {
      name,
      email,
      phone,
      type,
      bookType,
      num,
      pickup,
      location,
    };

    console.log(submitData);

    if (name === '' || email === '' || phone === '' || type === '' || bookType === '' || num === '' || pickup === '' || location === '') {
      toast.error('Please fill all the fields');
      return;
    }

    dispatch(donateStart());
    try {
      const res = await axiosInstance.post('/donation/donate', submitData);
      dispatch(donateSuccess(res.data));
      toast.success('Thank you for kind gesture');
      setName('');
      setEmail('');
      setPhone('');
      setType('');
      setAmount('');
      setBookType('');
      setNum('');
      setPickup('');
      setLocation('');
    } catch (err) {
      dispatch(donateFailure());
      toast.error(err.response.data.error);
      console.log(err.response);
    }
  };

  const dispatch = useDispatch();

  const handleMoneySubmit = async e => {
    e.preventDefault();
    const submitData = {
      name,
      email,
      phone,
      type,
      amount,
    };

    if (name === '' || email === '' || phone === '' || type === '' || amount === '') {
      toast.error('Please fill all the fields');
      return;
    }

    dispatch(donateStart());
    try {
      const res = await axiosInstance.post('/donation/donate', submitData);
      dispatch(donateSuccess(res.data));
      toast.success('Thank you for kind gesture');
      setName('');
      setEmail('');
      setPhone('');
      setType('');
      setAmount('');
      setBookType('');
      setNum('');
      setPickup('');
      setLocation('');
    } catch (err) {
      dispatch(donateFailure());
      toast.error(err.response.data.error);
    }
  };

  const preInfoProps = {
    name: name,
    setName: setName,
    email: email,
    setEmail: setEmail,
    phone: phone,
    setPhone: setPhone,
    type: type,
    setType: setType,
    setView: setView,
  };
  const bookProps = {
    bookType: bookType,
    setBookType: setBookType,
    num: num,
    setNum: setNum,
    pickup: pickup,
    setPickup: setPickup,
    infoView: infoView,
    setInfoView: setInfoView,
    location: location,
    setLocation: setLocation,
    setView: setView,
    clickEvent: handleBookSubmit,
  };
  const moneyProps = {
    amount: amount,
    setAmount: setAmount,
    setView: setView,
    clickEvent: handleMoneySubmit,
  };

  return (
    <div style={{ width: '100%' }}>
      <Navbar />

      <Container>
        {view === 'preinfo' ? <PreInfo {...preInfoProps} /> : view === 'money' ? <Money {...moneyProps} /> : <Book {...bookProps} />}

        <Left className='invisible lg:visible'>
          <img src={girl} alt='' />
        </Left>
      </Container>

      <Toaster />
    </div>
  );
};
export default Donate;
