import React, { useState } from 'react'
import styled from 'styled-components';

import { IoSearch } from 'react-icons/io5';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
`
const SearchWrapper = styled.div`
    color: ${props => props.theme.primary};
    select {
        background-color: ${props => props.theme.primary};
        color: ${props => props.theme.default};
        border-radius: 4px 0 0 4px;
    }
    input {
        background-color: ${props => props.theme.default};
        color: ${props => props.theme.primary};
        border-radius: 0 4px 4px 0;
        width: 100%;

        &::placeholder {
            font-weight: 300;
            font-family: 'Roboto', sans-serif;
        }
    }
    select, input {
        border: none;
        font-size: 18px;
        font-weight: 300;
        padding: 1rem;

        &:focus {
            outline: none;
        }
    }
`

const Header = ({ handleSearch, search }) => {
    const [showOptions, setShowOptions] = useState(false);
    // const [genre, setGenre] = useState('All');
    // const genres = ['All', 'Novel', 'Romance', 'Good', 'Bad'];

    return (
        <Container onClick={() => showOptions && setShowOptions(false)}>
            <div className='flex flex-col items-center gap-y-8'>
                <div className='text-center flex flex-col gap-y-4'>
                    <h1>Search a book from thousands of available books</h1>
                    <div className='text-center'>
                        <p>Have a particular book in mind? Try searching for it and find which library holds</p>
                        <p>position of it at the moment</p>
                    </div>
                </div>
            </div>

            <SearchWrapper className='w-full md:w-1/2 flex relative'>
                {/* <select>
                    {
                        genres.map((genre, index) => (
                            <option key={index} value={genre}>{genre}</option>
                        ))
                    }
                </select> */}
                <input 
                    value={search}
                    onChange={handleSearch}
                    placeholder='Searching for a book?'
                />
                <IoSearch className='text-[21px] absolute right-4 top-1/2 -translate-y-1/2' />
            </SearchWrapper>
        </Container>
    )
}

export default Header