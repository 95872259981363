import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';

import Button from '../../components/Button';
import Input from '../../components/Input';

import { MdOutlineNavigateNext, MdOutlineVisibilityOff, MdOutlineVisibility } from 'react-icons/md';

import { Container, Wrapper, BlueLight, Link, IconWrapper, InputWrapper } from './AuthStyles';

import { authFailure, authStart, loginSuccess } from '../../redux/features/authSlice';
import axiosInstance from '../../utils/axios.config';

const Login = () => {
  const quote = {
    text: 'I Guess there are never enough books.',
    quoteMaster: 'John Steinbeck',
  };

  const { isFetching } = useSelector(state => state.user);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const InputFields = [
    {
      label: 'Email Address',
      type: 'email',
      name: 'email',
      value: email,
      setValue: setEmail,
    }
  ];
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLoginSubmit = async() => {
    dispatch(authStart());
    try {
      const res = await axiosInstance.post('/login', { email, password });
      dispatch(loginSuccess(res.data));
      navigate('/');
    }
    catch(err) {
      dispatch(authFailure(err));
      toast.error(err.response.data.error);
    }
  };

  return (
    <Container>
      <div className='hidden md:flex w-1/3 flex-col gap-y-4'>
        {' '}
        {/* Quote */}
        <h2 className='uppercase'>{quote?.text}</h2>
        <h4>- {quote?.quoteMaster}</h4>
      </div>

      <div className='w-full md:w-1/2 relative px-8 py-4 z-10'>
        <BlueLight className='w-44 md:w-60' />

        <Wrapper className='p-8 md:p-12'>
          <h2>WELCOME BACK</h2>

          <div>
            {InputFields.map((item, index) => (
              <>
                <Input {...item} key={index} strong={false} />
              </>
            ))}
            <InputWrapper>
              <label>Password</label>
              <input type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
              {
                showPassword ?
                <MdOutlineVisibility className='text-[21px] absolute right-4 bottom-2' onClick={() => setShowPassword(false)}/>
                :
                <MdOutlineVisibilityOff className='text-[21px] absolute right-4 bottom-2' onClick={() => setShowPassword(true)}/>
              }
            </InputWrapper>
          </div>

          <div className='flex flex-col md:flex-row gap-4 justify-between items-center'>
            <Link>Issue Logging in?</Link>
            <Button text={'LOGIN'} clickEvent={handleLoginSubmit} strong={true} disabled={isFetching} />
          </div>
        </Wrapper>

        <IconWrapper className='absolute -right-6 top-1/2 -translate-x-1/2' onClick={() => navigate('/register')}>
          <MdOutlineNavigateNext className='text-[27px]' />
        </IconWrapper>
      </div>

      <Toaster />
    </Container>
  );
};
export default Login;
