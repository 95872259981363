import React, { useEffect } from 'react'
import styled from 'styled-components'

import Aos from 'aos';

import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { Girl } from '..'

const Container = styled.section`
    background-color: ${props => props.theme.primary};
    display: flex;
    align-items: flex-end;
    justify-content: end;
    padding: 2rem;
    border-radius: 14px;
    position: relative;
`
const IconWrapper = styled.div`
    height: max-content;
    padding: 0.75rem;
    cursor: pointer;
    background-color: ${props => props.theme.secondary};
    border-radius: 50%;
    color: ${props => props.theme.text};
`

const Explore = () => {
    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, [])

    return (
        <Container className='w-4/5 md:w-11/12 mx-auto xl:w-full h-[400px] md:h-[600px]' data-aos="fade-up">
            <img src={Girl} alt='Girl' className='h-11/12 md:h-[105%] w-auto absolute bottom-0 left-8 opacity-5 md:opacity-100' />
            <div className='md:w-1/3 lg:w-1/2 flex gap-x-4 items-end'>
                <div className='flex flex-col gap-y-4'>
                    <h3>Start exploring the vast numbers of books available and widen your</h3>
                    <h1>Wisdom</h1>
                </div>
                <IconWrapper>
                    <MdOutlineKeyboardArrowRight className='text-[27px]'/> 
                </IconWrapper>
            </div>
        </Container>
    )
}

export default Explore