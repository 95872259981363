import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Button from '../Button';
import Input from '../Input';
import { Container, Title, Inputs, ButtonDiv } from './DonateStyles';
import qr from '../../assets/images/qr.png';

const QrContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h4 {
    margin-bottom: 10px;
  }

  img {
    background-color: ${props => props.theme.default};
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
`;

const Money = ({ amount, setAmount, setView, clickEvent }) => {
  const { isFetching } = useSelector((state) => state.donate_data);

  const inputFields = [
    {
      label: 'Fund Amount',
      type: 'number',
      name: 'amount',
      value: amount,
      setValue: setAmount,
    },
  ];

  const handleBackClick = () => {
    setView('preinfo');
  };
  return (
    <Container>
      <Title>
        <h2>Donate Fund</h2>
      </Title>
      <Inputs>
        {inputFields.map((item, i) => (
          <Input key={i} {...item} strong={false} />
        ))}
        <QrContainer>
          <h4>Scan the QR below to directly donate the amount</h4>
          <img src={qr} alt='' />
        </QrContainer>
      </Inputs>
      <ButtonDiv>
        <Button text={'Back'} strong={false} clickEvent={handleBackClick} />
        <Button text={'Submit'} strong={true} clickEvent={clickEvent} disabled={isFetching}/>
      </ButtonDiv>
    </Container>
  );
};
export default Money;
