import React from 'react';
import styled from 'styled-components';
import { LOGO } from '..';

import { AiFillTwitterCircle } from 'react-icons/ai';
import { FaFacebook, FaTelegram } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
const Icons = styled.a`
  color: ${props => props.theme.text};
  border-radius: 50%;
  cursor: pointer;
  font-size: 27px;
  text-decoration: none;
  &:hover {
    color: ${props => props.color};
  }
`;

const Social = () => {
  return (
    <div className='flex justify-between items-end w-full'>
      <img src={LOGO} alt='Logo' className='w-auto h-28' />

      <SocialIcons>
        {' '}
        {/* Social Media */}
        <Icons color='#c32aa3' href='https://www.instagram.com/the_wisdom_point/' target='_blank' rel='noreferrer'>
          <RiInstagramFill />
        </Icons>
        <Icons color='#3b5998' href=''>
          <FaFacebook />
        </Icons>
        {/* <Icons color='#6cc1e3'>
          <FaTelegram />
        </Icons>
        <Icons color='#00acee'>
          <AiFillTwitterCircle />
        </Icons> */}
      </SocialIcons>
    </div>
  );
};

export default Social;
