import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/BooksComponents/Header'
import ListBooks from '../../components/BooksComponents/ListBooks'
import Navbar from '../../components/NavbarComponents/Navbar'
import { Section } from '../Home'
import { getAllBooks } from '../../redux/calls/apiBooks';

const Container = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  margin: 3rem 0;
`

const Books = () => {
  const { books, isFetching } = useSelector(state => state.books);

  const [search, setSearch] = useState('');
  const [filteredValue, setFilteredValue] = useState([]);
  
  const dispatch = useDispatch()
  useEffect(() => {
      getAllBooks(dispatch);
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setFilteredValue(books.filter(item => item.title.toLowerCase().includes(e.target.value.toLowerCase())));
  }

  useEffect(() => {
    if(search === '') {
      setFilteredValue(books);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <Section>
      <Navbar />
      <Container className=' '>
        <Header 
          search={search}
          handleSearch={handleSearch}
        />
        <ListBooks 
          books={filteredValue}
          isFetching={isFetching}
        />
      </Container>
    </Section>
  )
}

export default Books